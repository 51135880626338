/* nav and content container*/
/* variables for nav area sizes and colors */
$header-height: 64px;
$footer-height: 30px;
$sidebar-standard-width: 250px;
$sidebar-narrow-width: 75px;
$nav-dark-theme-border: #343a40;
$nav-dark-theme-background: #343a40;
$nav-dark-theme-text: white;
$nav-light-theme-border: #c8c8c8;
$nav-light-theme-background: #f4f4f4;
$nav-light-theme-text: #333333;

.ib-site-wrapper {
  min-height: calc(100vh - #{$header-height});
  position: relative;
}

.ib-nav-top {
  height: $header-height;
  max-height: $header-height;
  position: sticky;
  top: 0;
  z-index: 1020;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}

.ib-nav-top-content {
  padding: 0 15px;
  line-height: $header-height;
  font-size: 30px;
}

.ib-nav-top-content-item {
  padding: 0 5px 0 0;
  font-size: 20px;

  .dropdown-item {
    padding: 0 5px 0 5px;
    line-height: 30px;
  }

  a.dropdown-item, a:hover.dropdown-item {
    color: black;
  }
}

.ib-nav-top-content-button {
  background-color: transparent;
  margin-top: -10px;
}

.ib-nav-bottom {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: $footer-height;
  line-height: $footer-height;
  border-top-width: 1px;
  border-top-style: solid;
  z-index: 1000;
  overflow: hidden;

  &.mobile {
    position: absolute;
  }
}

.ib-nav-bottom-content {
  padding-left: 15px;
  font-size: 80%;
}

.ib-powered-by-centered {
  text-align: center;
}

.ib-powered-by-bottom-right {
  position: fixed;
  bottom: $footer-height;
  right: 0;
  z-index: 1000;
  overflow: hidden;

  &.mobile {
    position: absolute;
  }
}

.p-panelmenu {
  .p-panelmenu-content, .p-menuitem, .p-menuitem-link {
    a, a:hover {
      padding: 0.5em 0.5em 0.5em 2.5em !important;
    }
  }
}

.ib-menu-width-narrow * .p-panelmenu {
  .p-panelmenu-content, .p-menuitem, .p-menuitem-link {
    a, a:hover {
      padding: 0.5em 0.5em 0.5em 1.5em !important;
    }
  }
}

.p-panelmenu {
  .p-panelmenu-header {
    a, a:hover {
      padding: 0.5em 0.5em 0.5em 0.857em !important;
      border-width: 0 !important;
    }
  }
}

.p-panelmenu .p-panelmenu-header > a:focus {
    box-shadow: none !important;
}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:focus {
    box-shadow: none !important;
}

.p-panelmenu p.panelmenu-content {
    padding: 0 !important;
}



.ib-nav-dark {
  background-color: $nav-dark-theme-background;
  color: $nav-dark-theme-text;
  border-color: $nav-dark-theme-border;

  a, a:hover {
    color: $nav-dark-theme-text;
    text-decoration: none;
  }

  .ib-nav-top-content-button {
    color: $nav-dark-theme-text;
  }

  .btn.focus, .btn:focus {
    box-shadow: none;
  }

  .p-panelmenu-panel {
    .p-panelmenu-header {
      a {
        border-color: $nav-dark-theme-border !important;
        background-color: $nav-dark-theme-background !important;
        color: $nav-dark-theme-text !important;
      }
    }
  }

  .p-panelmenu-panel {
    .p-panelmenu-content {
      border-color: $nav-dark-theme-border !important;
      background-color: $nav-dark-theme-background !important;
      color: $nav-dark-theme-text !important;
      padding: 0 !important;
    }
  }

  .p-panelmenu-panel {
    .p-panelmenu-content {
      a, a:hover {
        border-color: $nav-dark-theme-border !important;
        background-color: $nav-dark-theme-background !important;
        color: $nav-dark-theme-text !important;
      }
    }
  }

  .p-menuitem-text, .p-menuitem-icon, .p-panelmenu-icon {
    border-color: $nav-dark-theme-border !important;
    background-color: $nav-dark-theme-background !important;
    color: $nav-dark-theme-text !important;
  }

  .p-slidemenu-wrapper, .p-slidemenu-content, .p-submenu-icon, ui-slidemenu-backward {
    border-color: $nav-dark-theme-border !important;
    background-color: $nav-dark-theme-background !important;
    color: $nav-dark-theme-text !important;
  }

  .p-slidemenu {
    .p-menuitem-link, .p-menuitem-active {
      border-color: $nav-dark-theme-border !important;
      background-color: $nav-dark-theme-background !important;
      color: $nav-dark-theme-text !important;
    }
  }
}
/*
Panel Menu Name	Element
ui-panelmenu	Container element.
ui-panelmenu-header	Accordion header of root submenu.
ui-panelmenu-content	Accordion content of root submenu.
ui-menu-list	List element.
ui-menuitem	Menuitem element.
ui-menuitem-text	Label of a menuitem.
ui-menuitem-icon	Icon of a menuitem.
ui-panelmenu-icon	Arrow icon of an accordion header.

Slide Menu Name Element
ui-slidemenu	Container element.
ui-slidemenu-wrapper	Wrapper of content.
ui-slidemenu-content	Content element.
ui-slidemenu-backward	Element to navigate to previous menu on click.
ui-menu-list	List element.
ui-menuitem	Menuitem element.
ui-menuitem-text	Label of a menuitem.
ui-menuitem-icon	Icon of a menuitem.
ui-submenu-icon	Arrow icon of a submenu.
*/




.ib-nav-light {
  background-color: $nav-light-theme-background;
  color: $nav-light-theme-text;
  border-color: $nav-light-theme-border;

  a, a:hover {
    color: $nav-light-theme-text;
    text-decoration: none;
  }

  .ib-nav-top-content-button {
    color: $nav-light-theme-text;
  }

  .btn.focus, .btn:focus {
    box-shadow: none;
  }

  .p-panelmenu-panel {
    .p-panelmenu-header {
      a {
        border-color: $nav-light-theme-border !important;
        background-color: $nav-light-theme-background !important;
        color: $nav-light-theme-text !important;
      }
    }
  }

  .p-panelmenu-panel {
    .p-panelmenu-content {
      border-color: $nav-light-theme-border !important;
      background-color: $nav-light-theme-background !important;
      color: $nav-light-theme-text !important;
    }
  }

  .p-panelmenu-panel {
    .p-panelmenu-content {
      a, a:hover {
        border-color: $nav-light-theme-border !important;
        background-color: $nav-light-theme-background !important;
        color: $nav-light-theme-text !important;
      }
    }
  }

  .p-menuitem-text, .p-menuitem-icon, .p-panelmenu-icon {
    border-color: $nav-light-theme-border !important;
    background-color: $nav-light-theme-background !important;
    color: $nav-light-theme-text !important;
  }

  .p-slidemenu-wrapper, .p-slidemenu-content, .p-submenu-icon, ui-slidemenu-backward {
    border-color: $nav-light-theme-border !important;
    background-color: $nav-light-theme-background !important;
    color: $nav-light-theme-text !important;
  }

  .p-slidemenu {
    .p-menuitem-link, .p-menuitem-active {
      border-color: $nav-light-theme-border !important;
      background-color: $nav-light-theme-background !important;
      color: $nav-light-theme-text !important;
    }
  }
}


.ib-site-container {
  padding: 5px 0 0 0;
  padding-bottom: 2 * $footer-height;
}

@media (min-width: 769px) {
  .ib-sidebar-container {
    position: fixed;
    top: $header-height;
    bottom: $footer-height;
    left: 0;
    z-index: 1000;
    display: block;
    overflow-x: hidden;
    overflow-y: auto;

    &.ib-menu-width-standard {
      width: $sidebar-standard-width;
      max-width: $sidebar-standard-width;
    }

    &.ib-menu-width-narrow {
      width: $sidebar-narrow-width;
      max-width: $sidebar-narrow-width;
      -webkit-transition-timing-function: ease;
      -o-transition-timing-function: ease;
      transition-timing-function: ease;
      -webkit-transition-duration: .35s;
      -o-transition-duration: .35s;
      transition-duration: .35s;
      -webkit-transition-property: height, visibility;
      -o-transition-property: height, visibility;
      transition-property: height, visibility;
    }

    &.ib-menu-width-hidden {
      width: 0;
      max-width: 0;
    }
  }

  .ib-sidebar-collapse-icon {
    position: fixed;
    top: $header-height;
    color: black;
    cursor: pointer;
    z-index: 9999; /* won't be clickable if not on top */
    &.ib-menu-width-standard {
      left: $sidebar-standard-width + 2px;
    }

    &.ib-menu-width-narrow {
      left: $sidebar-narrow-width + 2px;
    }
  }

  .ib-site-container {
    position: inherit;

    &.ib-menu-width-standard {
      margin: 0 0 0 $sidebar-standard-width;
    }

    &.ib-menu-width-narrow {
      margin: 0 0 0 $sidebar-narrow-width;
    }

    &.ib-menu-width-hidden {
      margin: 0 0 0 0;
    }
  }

  .ib-nav-bottom-content {
    &.ib-menu-width-standard {
      margin: 0 0 0 $sidebar-standard-width;
    }

    &.ib-menu-width-narrow {
      margin: 0 0 0 $sidebar-narrow-width;
    }

    &.ib-menu-width-hidden {
      margin: 0 0 0 0;
    }
  }
}
