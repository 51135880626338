@charset "UTF-8";
/* nav and content container*/
/* variables for nav area sizes and colors */
@import "~@angular/cdk/overlay-prebuilt.css";
.ib-site-wrapper {
  min-height: calc(100vh - 64px);
  position: relative;
}

.ib-nav-top {
  height: 64px;
  max-height: 64px;
  position: sticky;
  top: 0;
  z-index: 1020;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}

.ib-nav-top-content {
  padding: 0 15px;
  line-height: 64px;
  font-size: 30px;
}

.ib-nav-top-content-item {
  padding: 0 5px 0 0;
  font-size: 20px;
}
.ib-nav-top-content-item .dropdown-item {
  padding: 0 5px 0 5px;
  line-height: 30px;
}
.ib-nav-top-content-item a.dropdown-item, .ib-nav-top-content-item a:hover.dropdown-item {
  color: black;
}

.ib-nav-top-content-button {
  background-color: transparent;
  margin-top: -10px;
}

.ib-nav-bottom {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 30px;
  line-height: 30px;
  border-top-width: 1px;
  border-top-style: solid;
  z-index: 1000;
  overflow: hidden;
}
.ib-nav-bottom.mobile {
  position: absolute;
}

.ib-nav-bottom-content {
  padding-left: 15px;
  font-size: 80%;
}

.ib-powered-by-centered {
  text-align: center;
}

.ib-powered-by-bottom-right {
  position: fixed;
  bottom: 30px;
  right: 0;
  z-index: 1000;
  overflow: hidden;
}
.ib-powered-by-bottom-right.mobile {
  position: absolute;
}

.p-panelmenu .p-panelmenu-content a, .p-panelmenu .p-panelmenu-content a:hover, .p-panelmenu .p-menuitem a, .p-panelmenu .p-menuitem a:hover, .p-panelmenu .p-menuitem-link a, .p-panelmenu .p-menuitem-link a:hover {
  padding: 0.5em 0.5em 0.5em 2.5em !important;
}

.ib-menu-width-narrow * .p-panelmenu .p-panelmenu-content a, .ib-menu-width-narrow * .p-panelmenu .p-panelmenu-content a:hover, .ib-menu-width-narrow * .p-panelmenu .p-menuitem a, .ib-menu-width-narrow * .p-panelmenu .p-menuitem a:hover, .ib-menu-width-narrow * .p-panelmenu .p-menuitem-link a, .ib-menu-width-narrow * .p-panelmenu .p-menuitem-link a:hover {
  padding: 0.5em 0.5em 0.5em 1.5em !important;
}

.p-panelmenu .p-panelmenu-header a, .p-panelmenu .p-panelmenu-header a:hover {
  padding: 0.5em 0.5em 0.5em 0.857em !important;
  border-width: 0 !important;
}

.p-panelmenu .p-panelmenu-header > a:focus {
  box-shadow: none !important;
}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:focus {
  box-shadow: none !important;
}

.p-panelmenu p.panelmenu-content {
  padding: 0 !important;
}

.ib-nav-dark {
  background-color: #343a40;
  color: white;
  border-color: #343a40;
}
.ib-nav-dark a, .ib-nav-dark a:hover {
  color: white;
  text-decoration: none;
}
.ib-nav-dark .ib-nav-top-content-button {
  color: white;
}
.ib-nav-dark .btn.focus, .ib-nav-dark .btn:focus {
  box-shadow: none;
}
.ib-nav-dark .p-panelmenu-panel .p-panelmenu-header a {
  border-color: #343a40 !important;
  background-color: #343a40 !important;
  color: white !important;
}
.ib-nav-dark .p-panelmenu-panel .p-panelmenu-content {
  border-color: #343a40 !important;
  background-color: #343a40 !important;
  color: white !important;
  padding: 0 !important;
}
.ib-nav-dark .p-panelmenu-panel .p-panelmenu-content a, .ib-nav-dark .p-panelmenu-panel .p-panelmenu-content a:hover {
  border-color: #343a40 !important;
  background-color: #343a40 !important;
  color: white !important;
}
.ib-nav-dark .p-menuitem-text, .ib-nav-dark .p-menuitem-icon, .ib-nav-dark .p-panelmenu-icon {
  border-color: #343a40 !important;
  background-color: #343a40 !important;
  color: white !important;
}
.ib-nav-dark .p-slidemenu-wrapper, .ib-nav-dark .p-slidemenu-content, .ib-nav-dark .p-submenu-icon, .ib-nav-dark ui-slidemenu-backward {
  border-color: #343a40 !important;
  background-color: #343a40 !important;
  color: white !important;
}
.ib-nav-dark .p-slidemenu .p-menuitem-link, .ib-nav-dark .p-slidemenu .p-menuitem-active {
  border-color: #343a40 !important;
  background-color: #343a40 !important;
  color: white !important;
}

/*
Panel Menu Name	Element
ui-panelmenu	Container element.
ui-panelmenu-header	Accordion header of root submenu.
ui-panelmenu-content	Accordion content of root submenu.
ui-menu-list	List element.
ui-menuitem	Menuitem element.
ui-menuitem-text	Label of a menuitem.
ui-menuitem-icon	Icon of a menuitem.
ui-panelmenu-icon	Arrow icon of an accordion header.

Slide Menu Name Element
ui-slidemenu	Container element.
ui-slidemenu-wrapper	Wrapper of content.
ui-slidemenu-content	Content element.
ui-slidemenu-backward	Element to navigate to previous menu on click.
ui-menu-list	List element.
ui-menuitem	Menuitem element.
ui-menuitem-text	Label of a menuitem.
ui-menuitem-icon	Icon of a menuitem.
ui-submenu-icon	Arrow icon of a submenu.
*/
.ib-nav-light {
  background-color: #f4f4f4;
  color: #333333;
  border-color: #c8c8c8;
}
.ib-nav-light a, .ib-nav-light a:hover {
  color: #333333;
  text-decoration: none;
}
.ib-nav-light .ib-nav-top-content-button {
  color: #333333;
}
.ib-nav-light .btn.focus, .ib-nav-light .btn:focus {
  box-shadow: none;
}
.ib-nav-light .p-panelmenu-panel .p-panelmenu-header a {
  border-color: #c8c8c8 !important;
  background-color: #f4f4f4 !important;
  color: #333333 !important;
}
.ib-nav-light .p-panelmenu-panel .p-panelmenu-content {
  border-color: #c8c8c8 !important;
  background-color: #f4f4f4 !important;
  color: #333333 !important;
}
.ib-nav-light .p-panelmenu-panel .p-panelmenu-content a, .ib-nav-light .p-panelmenu-panel .p-panelmenu-content a:hover {
  border-color: #c8c8c8 !important;
  background-color: #f4f4f4 !important;
  color: #333333 !important;
}
.ib-nav-light .p-menuitem-text, .ib-nav-light .p-menuitem-icon, .ib-nav-light .p-panelmenu-icon {
  border-color: #c8c8c8 !important;
  background-color: #f4f4f4 !important;
  color: #333333 !important;
}
.ib-nav-light .p-slidemenu-wrapper, .ib-nav-light .p-slidemenu-content, .ib-nav-light .p-submenu-icon, .ib-nav-light ui-slidemenu-backward {
  border-color: #c8c8c8 !important;
  background-color: #f4f4f4 !important;
  color: #333333 !important;
}
.ib-nav-light .p-slidemenu .p-menuitem-link, .ib-nav-light .p-slidemenu .p-menuitem-active {
  border-color: #c8c8c8 !important;
  background-color: #f4f4f4 !important;
  color: #333333 !important;
}

.ib-site-container {
  padding: 5px 0 0 0;
  padding-bottom: 60px;
}

@media (min-width: 769px) {
  .ib-sidebar-container {
    position: fixed;
    top: 64px;
    bottom: 30px;
    left: 0;
    z-index: 1000;
    display: block;
    overflow-x: hidden;
    overflow-y: auto;
  }
  .ib-sidebar-container.ib-menu-width-standard {
    width: 250px;
    max-width: 250px;
  }
  .ib-sidebar-container.ib-menu-width-narrow {
    width: 75px;
    max-width: 75px;
    -webkit-transition-timing-function: ease;
    -o-transition-timing-function: ease;
    transition-timing-function: ease;
    -webkit-transition-duration: 0.35s;
    -o-transition-duration: 0.35s;
    transition-duration: 0.35s;
    -webkit-transition-property: height, visibility;
    -o-transition-property: height, visibility;
    transition-property: height, visibility;
  }
  .ib-sidebar-container.ib-menu-width-hidden {
    width: 0;
    max-width: 0;
  }

  .ib-sidebar-collapse-icon {
    position: fixed;
    top: 64px;
    color: black;
    cursor: pointer;
    z-index: 9999;
    /* won't be clickable if not on top */
  }
  .ib-sidebar-collapse-icon.ib-menu-width-standard {
    left: 252px;
  }
  .ib-sidebar-collapse-icon.ib-menu-width-narrow {
    left: 77px;
  }

  .ib-site-container {
    position: inherit;
  }
  .ib-site-container.ib-menu-width-standard {
    margin: 0 0 0 250px;
  }
  .ib-site-container.ib-menu-width-narrow {
    margin: 0 0 0 75px;
  }
  .ib-site-container.ib-menu-width-hidden {
    margin: 0 0 0 0;
  }

  .ib-nav-bottom-content.ib-menu-width-standard {
    margin: 0 0 0 250px;
  }
  .ib-nav-bottom-content.ib-menu-width-narrow {
    margin: 0 0 0 75px;
  }
  .ib-nav-bottom-content.ib-menu-width-hidden {
    margin: 0 0 0 0;
  }
}
/* forms */
.label-strong {
  font-weight: bold;
}

.label-right {
  text-align: right;
}

.form-group {
  margin-bottom: 1rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}

.form-group-tight {
  margin-bottom: 0.5rem;
}

.form-group-tightest {
  margin-bottom: 0.05rem;
}

.form-control-xs {
  height: calc(1.2em + 0.5rem + 2px);
  padding: 0.15rem 0.15rem;
  font-size: 0.875rem;
  line-height: 1.2;
  border-radius: 0.2rem;
}

.form-control-inline {
  display: inline;
  width: auto;
}

input.ng-touched.ng-invalid:not(form) {
  border-left: 5px solid #a94442;
  /* red */
}

input.ng-dirty.ng-invalid:not(form) {
  border-left: 5px solid #a94442;
  /* red */
}

select.ng-touched.ng-invalid:not(form) {
  border-left: 5px solid #a94442;
  /* red */
}

select.ng-dirty.ng-invalid:not(form) {
  border-left: 5px solid #a94442;
  /* red */
}

textarea.ng-touched.ng-invalid:not(form) {
  border-left: 5px solid #a94442;
  /* red */
}

textarea.ng-dirty.ng-invalid:not(form) {
  border-left: 5px solid #a94442;
  /* red */
}

.input-invalid {
  color: #a94442;
  /* red */
}

ul.input-invalid {
  margin-bottom: 0;
  -webkit-padding-start: 20px;
  line-height: 1;
}

.ui-multiselect:not(.ui-state-disabled):hover {
  border-color: #a6a6a6;
}

.ui-multiselect-normal .ui-multiselect {
  width: 100%;
}

.ui-multiselect-normal .ui-multiselect-panel {
  max-height: 300px;
}

.ui-multiselect-normal .ui-multiselect-items-wrapper {
  max-height: 250px;
}

.form-design-mode-control {
  border: 1px dotted blue;
  margin-top: 1px;
  margin-left: 1px;
  margin-right: 1px;
}

/*
  table theming
  default = as-is
  plain = ui-table-component-plain and ui-table-plain
  striped = ui-table-component-plain and ui-table-striped
*/
/* tone down the column sorting indicators */
body .p-table .p-sortable-column.p-state-highlight {
  background-color: unset;
  color: unset;
}
body .p-table .p-sortable-column.p-state-highlight .p-sortable-column-icon {
  color: #848484;
}

body .p-table .p-sortable-column.p-state-highlight a,
body .p-table .p-sortable-column.p-state-highlight a:hover {
  color: unset;
}

body .p-table .p-sortable-column a,
body .p-table .p-sortable-column a:hover {
  color: unset;
}

body .p-table .p-sortable-column:not(.p-state-highlight):hover {
  background-color: unset;
  color: unset;
}

/* footer when used should just have top border */
body .p-table .p-table-tfoot > tr > td {
  border: none;
  border-top: 1px solid #c8c8c8;
}

/* tone down the row padding and header borders*/
.p-datatable .p-datatable-header {
  background: initial;
  border-width: 0;
  padding: 0.5rem 0.5rem;
}

.p-datatable .p-datatable-thead > tr > th {
  border-width: 0;
  padding: 0.6rem 0.5rem;
}

.p-datatable .p-datatable-tbody > tr > td {
  padding: 0.6rem 0.5rem;
}

.p-treetable .p-treetable-thead > tr > th {
  border-width: 0;
  padding: 0.6rem 0.5rem;
}

.p-treetable .p-treetable-tbody > tr > td {
  padding: 0.6rem 0.5rem;
}

/* introduce plain and striped themes */
body .p-table-component-plain .p-paginator {
  background-color: white;
  border: none;
  border-top: 1px solid gray;
}

body .p-table-component-plain .p-table-caption,
body .p-table-component-plain .p-table-summary {
  background-color: white;
  border: none;
}

body .p-table-plain .p-table-thead > tr > th,
body .p-table-striped .p-table-thead > tr > th {
  border: none;
  border-bottom: 1px solid gray;
  background-color: white;
}

body .p-table-plain .p-table-tbody > tr > td,
body .p-table-striped .p-table-tbody > tr > td {
  border: none;
}

body .p-table-plain .p-table-tbody > tr:nth-child(even) {
  background-color: white;
}

/* don't block wordwrap */
.p-datatable-resizeable .p-datatable-thead > tr > th,
.p-datatable-resizeable .p-datatable-tfoot > tr > td,
.p-datatable-resizeable .p-datatable-tbody > tr > td,
.p-datatable .p-datatable-tbody > tr > td {
  white-space: initial !important;
}

/*
  body .p-table .p-table-thead > tr > th background-color #f4f4f4

  We need this background color for the whole div so the area above the scroll bar has the same background color so
  we don't have an ugly gap when it comes to scenarios where we have a header bar for search, features, add button, etc.
*/
.p-table-scrollable-header {
  background-color: #f4f4f4 !important;
}

/* table column filter overlay does not want any padding for the overlay container just straight to the contents and !important needed for that to happen */
.p-table-column-filter-overlay {
  padding: 0 0 0 0;
}

.p-table-column-filter-overlay .p-overlaypanel .p-overlaypanel-content {
  padding: 0 0 0 0 !important;
}

.p-table-column-filter-overlay .p-overlaypanel-content {
  padding: 0 0 0 0 !important;
}

.p-table-rows-message {
  margin-top: 5px;
  padding-left: 10px;
  margin-right: -100px;
  /* we don't want a long rows message to push the page number selector too far right */
}

/* In table row drop down menu truncated if we don't make overflow-x visible ...
  bootstrap button dropdown menu appearing behind other controls ... z-index doesn't cut it.*/
.table-responsive {
  overflow-x: visible;
}

/* option for stripped div based row/col instead of table */
.row-striped:nth-of-type(odd) div {
  background-color: #f9f9f9;
}

.row-striped:nth-of-type(even) div {
  background: #FFFFFF;
}

.row-border-top-bottom {
  border-top: 1px solid darkgrey;
  border-bottom: 1px solid darkgrey;
  margin-bottom: -1px;
  /*outline: 1px solid black;*/
}

/* flex table */
.flex-table {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  border: 1px solid #f2f2f2;
  font-size: 1rem;
  margin: 0.5rem;
  line-height: 1.5;
}
.flex-table-header {
  display: none;
}
@media (min-width: 500px) {
  .flex-table-header {
    font-weight: 700;
    background-color: #f2f2f2;
  }
}
.flex-table-row {
  width: 100%;
}
.flex-table-row:nth-of-type(even) {
  background-color: #f2f2f2;
}
.flex-table-row:nth-of-type(odd) {
  background-color: #ffffff;
}
@media (min-width: 500px) {
  .flex-table-row {
    display: flex;
    flex-flow: row nowrap;
  }
  .flex-table-row:nth-of-type(even) {
    background-color: #ffffff;
  }
  .flex-table-row:nth-of-type(odd) {
    background-color: #f2f2f2;
  }
}
.flex-table-row-item {
  display: flex;
  flex-flow: row nowrap;
  flex-grow: 1;
  flex-basis: 0;
  padding: 0.5em;
  word-break: break-word;
}
.flex-table-row-item:before {
  content: attr(data-header);
  width: 30%;
  font-weight: 700;
}
@media (min-width: 500px) {
  .flex-table-row-item {
    border: 1px solid #ffffff;
    padding: 0.5em;
  }
  .flex-table-row-item:before {
    content: none;
  }
}

.u-flex-grow1 {
  flex-grow: 1;
}

.u-flex-grow2 {
  flex-grow: 2;
}

.u-flex-grow3 {
  flex-grow: 3;
}

.u-flex-grow4 {
  flex-grow: 4;
}

.u-flex-grow5 {
  flex-grow: 5;
}

.u-flex-grow6 {
  flex-grow: 6;
}

.u-flex-grow7 {
  flex-grow: 7;
}

.u-flex-grow8 {
  flex-grow: 8;
}

.u-flex-grow9 {
  flex-grow: 9;
}

.u-flex-grow10 {
  flex-grow: 10;
}

/* loading */
.loading-container {
  position: relative;
  min-height: 50px;
}

.loading-template {
  top: 0;
  z-index: 2002;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #00000080;
  /*#000000ba;*/
  border-radius: 3px;
}

.loading-template h1,
.loading-template h2,
.loading-template h3,
.loading-template h4,
.loading-template h5,
.loading-template h6,
.loading-template p {
  color: white;
}

/* show something can be clicked with pointer cursor*/
.clickable {
  cursor: pointer;
}

/* show something can be moved with pointer cursor*/
.movable {
  cursor: move;
}

.text-black {
  color: #000;
}

/* Bootstrap 5 links are underlined by default (not just on hover)*/
a:link {
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

a:hover.text-black {
  color: #000;
}

.underline-on-hover:hover {
  text-decoration: underline;
}

.no-underline-on-hover:hover {
  text-decoration: none;
}

.highlight-hover-grey:hover {
  background-color: #f5f5f5 !important;
}

.align-right {
  text-align: right;
}

/* print mode changes */
.print-only {
  display: none;
}

@media print {
  .tab-content > .tab-pane {
    display: block !important;
    opacity: 1 !important;
    visibility: visible !important;
    border-bottom-style: solid;
    border-bottom-color: #212529;
    border-bottom-width: 1px;
    padding-top: 10px;
  }

  .ib-nav-top {
    display: none !important;
  }

  .ib-nav-bottom {
    display: none !important;
  }

  .ib-sidebar-container {
    display: none !important;
  }

  .ib-site-container {
    margin-left: 5px !important;
  }

  .nav-tabs {
    display: none !important;
  }

  .print-only {
    display: inherit !important;
  }

  button {
    display: none !important;
  }
}
/*callouts*/
.call-out-box {
  padding: 15px;
  margin: 15px 0;
  border: 1px solid #eee;
  border-left-width: 5px;
  border-radius: 3px;
}

.call-out-box h4 {
  margin-top: 0;
  margin-bottom: 5px;
}

.call-out-box p:last-child {
  margin-bottom: 0;
}

.call-out-box code {
  border-radius: 3px;
}

.call-out-box + .call-out-box {
  margin-top: -5px;
}

.call-out-box-danger {
  border-left-color: #ce4844;
}

.call-out-box-danger h4 {
  color: #ce4844;
}

.call-out-box-warning {
  border-left-color: #aa6708;
}

.call-out-box-warning h4 {
  color: #aa6708;
}

.call-out-box-info {
  border-left-color: #1b809e;
}

.call-out-box-info h4 {
  color: #1b809e;
}

/*boardered box*/
.boardered-box {
  padding: 10px;
  margin: 20px 0;
  border: 5px solid #eee;
  border-radius: 3px;
}

.boardered-box h4 {
  margin-top: 0;
  margin-bottom: 5px;
}

.boardered-box p:last-child {
  margin-bottom: 0;
}

.boardered-box code {
  border-radius: 3px;
}

.boardered-box + .boardered-box {
  margin-top: -5px;
}

.boardered-box-danger {
  border-color: #ce4844;
}

.boardered-box-danger h4 {
  color: #ce4844;
}

.boardered-box-warning {
  border-color: #aa6708;
}

.boardered-box-warning h4 {
  color: #aa6708;
}

.boardered-box-info {
  border-color: #1b809e;
}

.boardered-box-info h4 {
  color: #1b809e;
}

/*
   forced z-index when we need to override vendor set z-index
   sometimes primeng and bootstrap disagree about who should be at the top layer and we have to help
*/
.z500 {
  z-index: 500 !important;
}

.z1000 {
  z-index: 1000 !important;
}

.z2000 {
  z-index: 2000 !important;
}

.z3000 {
  z-index: 3000 !important;
}

/* text centered in hr-like line */
.center-separator {
  display: flex;
  line-height: 1em;
  color: gray;
}

.center-separator::before,
.center-separator::after {
  content: "";
  display: inline-block;
  flex-grow: 1;
  margin-top: 0.5em;
  background: gray;
  height: 1px;
  margin-right: 10px;
  margin-left: 10px;
}

/*when macro text gets replaced we have option to show what text originates with a macro*/
.macro-replacement-value {
  background-color: yellow;
  color: black;
}

.ui-state-highlight .macro-replacement-value {
  background-color: yellow;
  color: black;
}

/*
Notes
=====
There are four special combinators provided by CSS:
1. General sibling selector (~)
2. Descendant selector (space)
3. Child selector (>)
4. Adjacent sibling selector (+)

See https: //www.w3schools.in/css3/descendant-combinator-selector for examples.
*/
.cdk-overlay-container {
  z-index: 2000;
}

/* bootstrap tweaks */
/* tabs need a little whitespace between the tab and content */
.tab-pane {
  margin-top: 10px;
}

/* bootstrap 4 dropped xs button we want back */
.btn-group-xs > .btn,
.btn-xs {
  padding: 0.2rem 0.2rem;
  font-size: 0.75rem;
  line-height: 0.4;
  border-radius: 0.2rem;
}

.btn-group-xxs > .btn,
.btn-xxs {
  padding: 0.1rem 0.1rem;
  font-size: 0.65rem;
  line-height: 0.35;
  border-radius: 0.2rem;
}

/* bootstrap pills */
.nav-pills-tight .nav-link {
  padding: 0.25rem 0.5rem;
}

.nav-pills-round .nav-link {
  border-radius: 1rem;
}

/* bootstrap 5 introduced hover underline for nav links that we're not a fan of */
a:hover.nav-link {
  text-decoration: none;
}

/*bootstrap lets control prefix/suffix grow beyond the control height which looks jacked up*/
.input-group-text {
  max-height: calc(1.5em + 0.75rem + 2px);
}

/*bootstrap primary disabled is used for our save button which we want even more disabled looking*/
.btn-save.disabled,
.btn-save:disabled {
  background-color: #007bff9e;
  border-color: #007bff9e;
}

/*ng bootstrap scroll large typeahead results*/
ngb-typeahead-window.dropdown-menu {
  max-height: 500px !important;
  overflow-y: auto;
}

/*ng bootstrap scroll large dropdown menu*/
.ib-dropdown-scroll {
  /*max-height: calc(100vh - 50px);*/
  max-height: 200px;
  overflow-y: auto;
}

.ib-dropdown-scroll-large {
  /*max-height: calc(100vh - 50px);*/
  max-height: 500px;
  overflow-y: auto;
}

.card-header-tight {
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}

.card-body-tight {
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}

/*modal*/
.modal-larger {
  width: 75%;
  max-width: unset;
}

.modal-largest {
  width: 90%;
  max-width: unset;
}

.modal-body .container-fluid {
  /* remove double padding these two classes give together */
  padding-right: 0;
  padding-left: 0;
}

/* code tweaks */
pre {
  margin-bottom: 0;
}

/* bootstrap column narrow gutter helper */
.narrow-gutter {
  padding-left: 5px;
  padding-right: 5px;
}

/* bootstrap card helper */
.card-footer-small {
  padding: 0.25rem 0.75rem;
}

/* bootstrap popover helpers */
.popover-wide {
  width: 400px;
  max-width: 400px;
}

.popover-wider {
  width: 50%;
  max-width: 50%;
}

.popover-widest {
  width: 80%;
  max-width: 80%;
}

/* bootstrap clear button */
.btn-clear {
  color: #212529;
  background-color: inherit;
  border-color: #f8f9fa;
}

.btn-clear:hover {
  color: #212529;
  background-color: inherit;
  border-color: #f8f9fa;
}

.btn-invisible {
  color: #212529;
  background-color: inherit;
  border-color: inherit;
  border-width: 0;
}

.btn-invisible:hover {
  color: #212529;
  background-color: inherit;
  border-color: inherit;
  border-width: 0;
}

/* bootstrap default button */
.btn-default {
  color: #333;
  background-color: #eee;
  /*background-color: #e6e6e6;*/
  /*background-color: #fff;*/
  border-color: #ccc;
}

.btn-default:hover,
.btn-default:focus,
.btn-default.focus,
.btn-default:active,
.btn-default.active {
  color: #333;
  background-color: #e6e6e6;
  border-color: #adadad;
}

/* stronger hover background color for bootstrap dropdown */
.dropdown-item:hover {
  color: #16181b;
  text-decoration: none;
  background-color: #cecece75;
  /*#f8f9fa;*/
}

/* font awesome helpers */
/* note 'p' = 'point' so 1p5 is 1.5 since can't have period in class name */
.fa-0p25x {
  font-size: 0.25em;
}

.fa-0p5x {
  font-size: 0.5em;
}

.fa-1p5x {
  font-size: 1.5em;
}

.fa-2p5x {
  font-size: 2.5em;
}

.fa-3p5x {
  font-size: 3.5em;
}

.fa-4p5x {
  font-size: 4.5em;
}

.fa-5p5x {
  font-size: 5.5em;
}

.fa-6p5x {
  font-size: 6.5em;
}

.fa-7p5x {
  font-size: 7.5em;
}

.fa-8p5x {
  font-size: 8.5em;
}

.fa-9p5x {
  font-size: 9.5em;
}

.fa-15x {
  font-size: 15em;
}

.fa-20x {
  font-size: 20em;
}

.fa-25x {
  font-size: 25em;
}

.fa-30x {
  font-size: 30em;
}

/* prime ng tweaks */
/* when multiselect has custom header the close button is too far down */
/*.p-multiselect-close {
  margin-top: 0.5em !important;
  top: 0.5em !important;
}
*/
/* primeng dropdown group labels are disabled so they can't be selected but that makes them fainter
text when we want them to actually be more prominent to match typical html select groupings */
.primeng-select-item-group {
  font-weight: bolder !important;
  opacity: 1 !important;
  margin-left: -10px !important;
}

/* sometimes we need to dynamically decide if we want a dataview header or not */
.p-dataview-header-hidden > .p-dataview-header {
  display: none;
}

/* in some scenarios we want a tighter layout for dataview */
.p-dataview-tight > .p-dataview-header {
  padding: 0.25rem 1rem;
}

.p-dataview-tight .call-out-box {
  margin: 0;
  padding: 5px 15px;
}

.p-dataview-tight .p-paginator {
  padding: 0.25rem 0.75rem;
}

/* using disabled for group headers in multiselect so we don't want them dimmed */
.p-multiselect-item.p-corner-all.p-state-disabled {
  opacity: 1 !important;
}

/* multiselect label has bottom margin too large we want this to look like other select boxes */
.p-multiselect-label {
  margin-bottom: 0.2rem;
  padding: 0.375rem 0.75rem !important;
  padding-right: 2rem !important;
}

.p-multiselect-label-wrap {
  white-space: normal !important;
}

.p-multiselect {
  border: 1px solid #ced4da !important;
}

.p-multiselect-small {
  font-size: 0.85rem;
  line-height: 0.85rem;
}

.p-corner-all {
  border-radius: 0.25rem !important;
}

/* prime ng drop down items too much padding */
.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
  padding: 0.25rem 0.5rem 0.25rem 1.5rem;
}

.p-dropdown-panel .p-dropdown-header {
  padding: 0.75rem;
}

/* prime ng tree too much padding */
.p-tree {
  padding: 0.5rem 1rem;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content {
  padding: 0.25rem;
}

/*
  prime ng select multi-rows in table via row or checkbox
  See https://github.com/primefaces/primeng/issues/5179 for "simple CSS solution that worked"
*/
.p-table-tbody .p-chkbox {
  pointer-events: none;
}

/* prime ng galleria */
.p-panel-images {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.p-galleria-caption {
  width: 100% !important;
  bottom: 0 !important;
  padding: 0.2em 1em 0.2em 2em !important;
}

.p-galleria-nav-next,
.p-galleria-nav-prev {
  color: black !important;
}

/*doka image editor*/
.doka--root {
  --doka-effect--invert: 100%;
  --doka-color--alpha: #2990ff;
  --doka-color--alpha-dim: #1a80ec;
}

/*update stop theme.css*/
/*body .p-dropdown .p-dropdown-trigger .p-dropdown-trigger-icon:before {
  content: "";
}*/
/*body .p-multiselect .p-multiselect-trigger .p-multiselect-trigger-icon:before {
  content: "";
}*/
/*gridster*/
gridster {
  background: inherit !important;
}

gridster .gridster-column {
  /*border-left: 1px solid yellow;*/
  /*border-right: 1px solid red;*/
  outline: 1px solid yellow;
}

gridster .gridster-row {
  /*border-top: 1px solid yellow;*/
  /*border-bottom: 1px solid red;*/
  outline: 1px solid yellow;
}

/* avatar tweaks */
.inline-avatar .avatar-container {
  /* for when we want to be inline like inside drop down menu */
  display: inline-block !important;
}

/* archwizard tweaks */
/* the following three rules allow the wizard navigation bar to wrap for mobile widths */
aw-wizard-navigation-bar.horizontal ul.steps-indicator {
  flex-wrap: wrap;
}

aw-wizard-navigation-bar.horizontal ul.steps-indicator.steps-5 li {
  min-width: 100px;
  margin-bottom: 60px;
}

aw-wizard-step,
aw-wizard-completion-step {
  margin-top: -60px;
}